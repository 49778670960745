import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useTranslation } from "react-i18next"
import SocialShare from "../SocialShare"
import './news.scss'
import rehypeReact from 'rehype-react'
import MarkdownImage from '../MarkdownImage'
import InstagramWidget from "../InstagramWidget"

export default ({ data, recentNews, instaFeed, content }) => {
    // Will use default Namespace.
    const { t } = useTranslation();
    const renderAst = new rehypeReact({
        createElement: React.createElement,
        components: { 'markdown-image': MarkdownImage },
    }).Compiler

    return(
        <div id="news" className="blog-area pt-100 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 blog-post-item order-1 order-lg-2">
                        <div className="blog-wrapper blog-details">
                            <div className="blog-img img-full">
                                <Img sizes={data.image.childImageSharp.fluid} />   
                            </div>
                            <div className="blog-content">
                                <h3>{data.title}</h3>
                                { renderAst(content) }
                            </div>
                        </div>
                        <div className="common-tag-and-next-prev mt-60">
                            <SocialShare title={data.title} hashtags="news,stories"/>
                        </div>
                    </div>
                    <div className="col-lg-4 blog-sidebar order-2 order-lg-1">
                      <div className="widget mb-60">
                          <div className="widget-title">
                              <h4>{t('recent_news')}</h4>
                          </div>
                          <div className="sidebar-rc-post">
                              <ul>
                                  {recentNews.map(({node: {frontmatter: newsItem}}) => 
                                    newsItem.slug !== data.slug && (
                                    <li>
                                        <div className="rc-post-thumb img-full">
                                            <Link to={newsItem.slug}><img src={newsItem.image.childImageSharp.fluid.src} srcset={newsItem.image.childImageSharp.fluid.srcSet} sizes={newsItem.image.childImageSharp.fluid.sizes} alt="{newsItem.title" /></Link>
                                        </div>
                                        <div className="rc-post-content">
                                            <h4><Link to={newsItem.slug}>{newsItem.title}</Link></h4>
                                        </div>
                                    </li>
                                    )
                                  )}
                              </ul>
                          </div>
                      </div>
                      <div className="widget mb-60">
                        <InstagramWidget data={instaFeed} />
                      </div>
                    </div>
                </div>
            </div>
        </div>
    )}