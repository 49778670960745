import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import News from "../components/News"

const NewsPage = ({ pageContext: { localeData }, data }) => {

    const {frontmatter: fields} = data.page
    const {childImageSharp: bgImage} = data.bgImage

    return (
      <Layout localeData={localeData} title={fields.title}>
          <BookingLine localeData={localeData}/>
          <Breadcrumb slug={fields.slug} title={fields.title} bgImage={bgImage} />
          <News data={fields} recentNews={data.recentNews.edges} content={data.page.htmlAst} />
      </Layout>
    );
};
export default withI18next()(NewsPage);

export const query = graphql`
  query($id: String!, $locale: String!) {
    page: markdownRemark(id: { eq: $id })
    {
        htmlAst
        frontmatter {
            slug
            title
            date
            description
            image {
                childImageSharp {
                    fluid(maxWidth: 770, quality: 90) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
    bgImage: file(relativePath: { eq: "events/event_header_bg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    recentNews: allMarkdownRemark(
    filter: {frontmatter: {templateKey: {eq: "news"}},fields: {locale: {eq: $locale}}}
    sort: {fields: [frontmatter___date, frontmatter___title], order: [DESC]}
    limit: 4
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            date
            description
            image {
              childImageSharp {
                fluid(maxWidth: 120, quality: 60) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    
  }`